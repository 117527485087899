import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import { authSlice } from './auth/authSlice';
import { authListener } from '../view/components/user/User';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer)

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    // Add other reducers here
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
});

export const persistor = persistStore(store)
authListener(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
