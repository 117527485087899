import React from 'react';
import Catalog from '../../components/catalog/Catalog';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import courseDataMock from '../../../content/mockCourseData.json';
import { CourseData } from '../../components/catalog/Catalog';
import './CoursesPage.scss';
import SpecialtyCourseWidget from '../../components/specialty-course-widget/SpecialtyCourseWidget';

interface props {}

const CoursesPage: React.FC<props> = () => {
  const [courseCodeModelOpen, setCourseCodeModelOpen] = React.useState(true);

  const data = courseDataMock.courses as CourseData[];
  const courses: CourseData[] = data;
  return (
    <Layout>
      <Page title='Courses'>
        <Catalog courses={courses} setCourseCodeModelOpen={setCourseCodeModelOpen} />
        <SpecialtyCourseWidget
          courseCodeModelOpen={courseCodeModelOpen}
          setCourseCodeModelOpen={setCourseCodeModelOpen}
        />
      </Page>
    </Layout>
  );
};

export default CoursesPage;
