import React from 'react';
import './WidgetContainer.scss';

type WidgetContainerProps = {
  children: React.ReactNode;
  isModel?: boolean;
  isModelOpen?: boolean;
};

const WidgetContainer: React.FC<WidgetContainerProps> = ({
  children,
  isModel = false,
  isModelOpen,
}) => {
  if (isModel) {
    return (
      <div className={isModelOpen ? 'model-overlay' : 'hidden'}>
        <div className='widget-model'>
          <div className='widget'>{children}</div>
        </div>
      </div>
    );
  }
  return <div className='widget'>{children}</div>;
};

export default WidgetContainer;
