import React from 'react';
import './HorizontialRule.scss';

type Props = {
  text: string;
};

const HorizontialRule: React.FC<Props> = ({ text }) => {
  return <div className='horizontial-rule'>{text}</div>;
};

export default HorizontialRule;
