import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Form from '../form/Form';
import FormInput from '../form-input/FormInput';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Message, { MessageProps } from '../message/Message';
import { emailPattern } from '../../../utils/field-validations/fieldValidations';
import FormInputDate from '../form-input-date/FormInputDate';
import { updateUserEnrollmentAsync } from '../../../store/auth/authSlice';
import FormInputTextArea from '../form-input-text-area/FormInputTextArea';
import Button from '../button/Button';
import './EnrollmentForm.scss';

export type EnrollmentFormProps = {};

export type EnrollmentFields = {
  firstName: string;
  middleName?: string;
  lastName: string;
  birthdate: string;
  streetAddress: string;
  city: string;
  residentState: string;
  zipCode: string;
  phoneNumber: string;
  email: string;
  emergencyContact: string;
  emergencyContactNumber: string;
  backgroundExperiance?: string;
  backgroundImpairments?: string;
  courseId?: string;
};

const EnrollmentForm: React.FC<EnrollmentFormProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector((state) => state.auth.currentUser);

  const { pathname } = location;

  const [message, setMessage] = useState<MessageProps>({
    type: 'error',
    message: '',
  });

  const { class: classId } = useParams();

  const defaultValues: EnrollmentFields = {
    firstName: '',
    middleName: '',
    lastName: '',
    birthdate: '',
    streetAddress: '',
    city: '',
    residentState: '',
    zipCode: '',
    phoneNumber: '',
    email: '',
    emergencyContact: '',
    emergencyContactNumber: '',
    backgroundExperiance: '',
    backgroundImpairments: '',
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<EnrollmentFields>({
    defaultValues,
  });

  useEffect(() => {
    user && setValue('email', user.email || '');
    const [firstName, lastName] = user?.displayName?.split(' ') || [];
    firstName && setValue('firstName', firstName || '');
    lastName && setValue('lastName', lastName || '');
  }, [setValue, user]);

  const getErrorMessage = (error: string | undefined) => {
    switch (error) {
      case 'Firebase: Error (auth/invalid-email).':
        return 'Invalid email address.';
      case 'Firebase: Error (auth/user-disabled).':
        return 'User account has been disabled.';
      case 'Firebase: Error (auth/user-not-found).':
      case 'Firebase: Error (auth/wrong-password).':
        return 'Those credentials are not valid, try again.';
      case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
        return 'Too many failed login attempts, try again later or reset your password.';
      default:
        return 'An unknown error occurred.';
    }
  };

  const onSubmit = handleSubmit(async () => {
    const values = getValues();
    const studentData: EnrollmentFields = values;
    const courseData = {
      ...studentData,
      courseId: 'OUe8ON63nMudz75NNtNE',
    };
    dispatch(updateUserEnrollmentAsync(courseData)).then((result) => {
      if (updateUserEnrollmentAsync.fulfilled.match(result)) {
        navigate(`${pathname}/checkout`);
      } else {
        const error = getErrorMessage(result.payload);
        setMessage({
          message: error,
          type: 'error',
        });
      }
    });
  });

  return (
    <>
      <div className={'enroll-form'}>
        <h2>{`Registering for ${classId?.toLocaleUpperCase()}`}</h2>
        <h3>Your Information</h3>
        {message.message && <Message type={message.type} message={message.message} />}
        <Form onSubmit={onSubmit}>
          <FormInput<EnrollmentFields>
            id='firstName'
            type='text'
            name='firstName'
            label='First Name'
            placeholder='First Name'
            register={register}
            rules={{
              required: 'Enter your first name',
              // disabled: defaultValues.firstName ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='middleName'
            type='text'
            name='middleName'
            label='Middle Name (optional)'
            placeholder='Middle Name'
            register={register}
            rules={
              {
                // disabled: defaultValues.middleName ? true : false,
              }
            }
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='lastName'
            type='text'
            name='lastName'
            label='Last Name'
            placeholder='Last Name'
            register={register}
            rules={{
              required: 'Enter your middle name',
              // disabled: defaultValues.lastName ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='email'
            type='email'
            name='email'
            label='Email Address'
            placeholder='Email Address'
            register={register}
            rules={{
              required: 'Enter your email address.',
              pattern: emailPattern,
              // disabled: email ? true : false,
            }}
            errors={errors}
          />
          <FormInputDate<EnrollmentFields>
            id='birthdate'
            type='date'
            name='birthdate'
            label='Birthdate'
            placeholder='Birthdate'
            register={register}
            rules={{
              required: 'Enter your Birthdate address.',
              // disabled: defaultValues.birthdate ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='streetAddress'
            type='text'
            name='streetAddress'
            label='Street Address'
            placeholder='Street Address'
            register={register}
            rules={{
              required: 'Enter your street address.',
              // disabled: defaultValues.streetAddress ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='city'
            type='text'
            name='city'
            label='City'
            placeholder='City'
            register={register}
            rules={{
              required: 'Enter your city.',
              // disabled: defaultValues.city ? true : false
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='residentState'
            type='text'
            name='residentState'
            label='State'
            placeholder='State'
            register={register}
            rules={{
              required: 'Select your state.',
              // disabled: defaultValues.residentState ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='zipCode'
            type='text'
            name='zipCode'
            label='Zip Code'
            placeholder='Zip Code'
            register={register}
            rules={{
              required: 'Enter your zip code.',
              // disabled: defaultValues.zipCode ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='phoneNumber'
            type='text'
            name='phoneNumber'
            label='Phone Number'
            placeholder='Phone Number'
            register={register}
            rules={{
              required: 'Enter your phone number.',
              // disabled: defaultValues.phoneNumber ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='emergencyContact'
            type='text'
            name='emergencyContact'
            label='Emergency Contact'
            placeholder='Emergency Contact'
            register={register}
            rules={{
              required: 'Enter Your emergency contact name.',
              // disabled: defaultValues.emergencyContact ? true : false,
            }}
            errors={errors}
          />
          <FormInput<EnrollmentFields>
            id='emergencyContactNumber'
            type='text'
            name='emergencyContactNumber'
            label='Emergency Contact Phone Number'
            placeholder='Emergency Contact Phone Number'
            register={register}
            rules={{
              required: 'Enter your emergency contact phone number',
              // disabled: defaultValues.emergencyContactNumber ? true : false,
            }}
            errors={errors}
          />
          <FormInputTextArea<EnrollmentFields>
            id='backgroundExperiance'
            name='backgroundExperiance'
            label='Please provide any background firearms experience that may be helpful to the instructor. (optional)'
            placeholder='Please provide any background firearms experience that may be helpful to the instructor. (optional)'
            register={register}
            // rules={{ disabled: defaultValues.backgroundExperiance ? true : false }}
            errors={errors}
            rows={5}
          />
          <FormInputTextArea<EnrollmentFields>
            id='backgroundImpairments'
            name='backgroundImpairments'
            label='Please disclose any physical or mental impairments that may affect your ability to safely handle a firearm. (optional)'
            placeholder='Please disclose any physical or mental impairments that may affect your ability to safely handle a firearm. (optional)'
            register={register}
            // rules={{ disabled: defaultValues.backgroundImpairments ? true : false }}
            errors={errors}
            rows={5}
          />
          <Button type='submit'>Submit</Button>
        </Form>
      </div>
    </>
  );
};

export default EnrollmentForm;
