import React, { useEffect } from 'react';
import { auth } from '../../../firebase/firebase';
import { browserLocalPersistence } from 'firebase/auth';
import { useAppDispatch } from '../../../store/hooks';
import { authSlice } from '../../../store/auth/authSlice';
import { Dispatch } from '@reduxjs/toolkit';

export const authListener = (dispatch: Dispatch) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch(authSlice.actions.setCurrentUser(user));
    } else {
      dispatch(authSlice.actions.logout());
    }
  });
};

export const UserComponent: React.FC = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    // Set Firebase Authentication persistence to 'local' to keep the user logged in after refresh
    auth.setPersistence(browserLocalPersistence);

    // Listen for changes in the user authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Set a timer for automatic logout after 15 minutes of no activity
        let logoutTimer = setTimeout(() => {
          auth.signOut();
        }, 900000); // 900,000 milliseconds = 15 minutes

        // Reset the timer on any user activity
        document.addEventListener('click', () => {
          clearTimeout(logoutTimer);
          logoutTimer = setTimeout(() => {
            auth.signOut();
          }, 900000);
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [appDispatch]);

  return <></>;
};
