import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import PaymentForm from '../../components/payment-form/PaymentForm';
import WidgetContainer from '../../components/widget-container/WidgetContainer';
import './CheckoutPage.scss';

const CheckoutPage: React.FC = () => {
  const [handgun, setHandgun] = useState(false);
  const [rifle, setRifle] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(() => {
      let total = 0;
      if (handgun) total += 800;
      if (rifle) total += 400;
      return total;
    });
  }, [handgun, rifle]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'handgun') {
      setHandgun(checked);
    } else if (name === 'rifle') {
      setRifle(checked);
    }
  };

  return (
    <>
      <Layout>
        <Page title='Checkout'>
          <WidgetContainer>
            <h2>{`Idaho expeditionary course checkout`}</h2>
            <p>
              The course will be covering topics in handgun and rifle. Please check your email
              regularly for upcoming course details.
            </p>
            <h4>Please check which course segment(s) you will be attending: </h4>

            <div className='optional-checkout-group'>
              <span>
                <input
                  id='handgun'
                  name='handgun'
                  type='checkbox'
                  value={800}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor='handgun'>Handgun</label>
              </span>
              <span>$800.00</span>
            </div>
            <div className='optional-checkout-group'>
              <span>
                <input
                  id='rifle'
                  name='rifle'
                  type='checkbox'
                  value={400}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor='rifle'>Rifle</label>
              </span>
              <span>$400.00</span>
            </div>
            <div className='optional-checkout-group'>
              <h4>Total:</h4>
              <h4>${total}</h4>
            </div>
          </WidgetContainer>
          <PaymentForm price={total} />
        </Page>
      </Layout>
    </>
  );
};

export default CheckoutPage;
