import React, { forwardRef } from 'react';
import './InputTextArea.scss';

export type InputTextAreaProps = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  rows: number;
};

const InputTextArea: React.FC<InputTextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  InputTextAreaProps
>(({ id, name, label, placeholder, rows, ...props }, ref) => {
  return (
    <>
      <textarea
        className='input-text-area'
        id={id}
        ref={ref}
        name={name}
        aria-label={label}
        placeholder={placeholder}
        rows={rows}
        {...props}
      />
    </>
  );
});

export default InputTextArea;
