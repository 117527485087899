import React from 'react';
import './Button.scss';

interface ButtonProps {
  onClick?: () => void;
  type: 'button' | 'submit' | 'reset' | undefined;
  content?: any;
  className?: string;
  isLoading?: boolean;
  children?: any;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  type,
  content,
  className,
  isLoading = false,
}) => {
  return (
    <button
      className={className ? className : 'custom-button'}
      onClick={onClick}
      type={type}
      disabled={isLoading}
    >
      {isLoading ? <div className='button-spinner-container' /> : children || content}
    </button>
  );
};

export default Button;
