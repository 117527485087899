import React, { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginAsync } from '../../../store/auth/authSlice';
import Button from '../button/Button';
import { useAppDispatch } from '../../../store/hooks';
import Form from '../form/Form';
import WidgetContainer from '../widget-container/WidgetContainer';
import { emailPattern } from '../../../utils/field-validations/fieldValidations';
import Message, { MessageProps } from '../message/Message';
import FormInput from '../form-input/FormInput';

import './LoginWidget.scss';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';

export type LoginFormFields = {
  email: string;
  password: string;
};

export interface LoginProps {
  setWidget: any;
}

export interface Route {
  path: string;
}

const Login: React.FC<LoginProps> = ({ setWidget }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const routes: Route[] = [{ path: '/course-catalog/:course/:class' }];

  const useCurrentPath = () => {
    const location = useLocation();
    const match = matchRoutes(routes, location);
    if (match) {
      return match[0].route.path;
    }
  };

  const path = useCurrentPath();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormFields>();

  const [message, setMessage] = useState<MessageProps>({
    type: 'error',
    message: '',
  });

  const getErrorMessage = (error: string | undefined) => {
    switch (error) {
      case 'Firebase: Error (auth/invalid-email).':
        return 'Invalid email address.';
      case 'Firebase: Error (auth/user-disabled).':
        return 'User account has been disabled.';
      case 'Firebase: Error (auth/user-not-found).':
      case 'Firebase: Error (auth/wrong-password).':
        return 'Those credentials are not valid, try again.';
      case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
        return 'Too many failed login attempts, try again later or reset your password.';
      default:
        return 'An unknown error occurred.';
    }
  };

  const onSubmit = handleSubmit(async () => {
    const values = getValues();
    const loginData: LoginFormFields = values;
    dispatch(loginAsync(loginData)).then((result) => {
      if (loginAsync.fulfilled.match(result)) {
        if (path?.includes('/login')) {
          navigate('/');
        }
      } else {
        const error = getErrorMessage(result.payload);
        setMessage({
          message: error,
          type: 'error',
        });
      }
    });
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/');
      }
    });
  }, [navigate]);

  return (
    <WidgetContainer>
      <h2>Sign In</h2>
      {message.message && <Message type={message.type} message={message.message} />}
      <Form onSubmit={onSubmit}>
        <FormInput<LoginFormFields>
          id='email'
          type='email'
          name='email'
          label='Email Address'
          placeholder='Email Address'
          register={register}
          rules={{
            required: 'Enter your email address.',
            pattern: emailPattern,
          }}
          errors={errors}
        />
        <FormInput<LoginFormFields>
          id='password'
          type='password'
          name='password'
          label='Password'
          placeholder='Password'
          register={register}
          rules={{
            required: 'Enter your password.',
          }}
          errors={errors}
        />
        <Button type='button' className='blue-link-button' onClick={() => setWidget('register')}>
          Don't have an account?
        </Button>
        <Button
          type='button'
          className='blue-link-button'
          onClick={() => setWidget('password-reset')}
        >
          Forget your password?
        </Button>
        <div className='button-container'>
          <Button type='submit'>Sign In</Button>
        </div>
      </Form>
    </WidgetContainer>
  );
};

export default Login;
