import { get } from 'lodash';
import {
  RegisterOptions,
  UseFormRegister,
  Path,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import InputTextArea, { InputTextAreaProps } from '../input-text-area/InputTextArea';
import { FormErrorMessage } from '../form-error-message/FormErrorMessage';

export type FormInputTextAreaProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  rows?: number;
} & Omit<InputTextAreaProps, 'name'>;

const FormInputTextArea = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  rows = 5,
  ...props
}: FormInputTextAreaProps<TFormValues>): JSX.Element => {
  const errorMessages = get(errors, name)?.message;
  const hasError = !!(errors && errorMessages);

  return (
    <div className='form-field-container' aria-live='polite'>
      <InputTextArea
        name={name}
        aria-invalid={hasError}
        rows={rows}
        {...props}
        {...(register && register(name, rules))}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </div>
  );
};

export default FormInputTextArea;
