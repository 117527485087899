import React, { useState } from 'react';
import Markup from '../markup/Markup';
import Button from '../button/Button';
import './marketing-horizontial.scss';
import { Link } from 'react-router-dom';

interface MarketingProps {
  imageSrc?: string;
  title: string;
  description: string;
  toLink?: string;
}

const MarketingHorizontial: React.FC<MarketingProps> = ({
  imageSrc,
  title,
  description,
  toLink,
}) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className='marketing-container'>
      {imageSrc && <img src={imageSrc} alt='Marketing' className='marketing-image' />}
      <div className='marketing-text-container'>
        <h2>{title}</h2>
        <div className={!showFullContent ? 'marketing-content' : 'marketing-content-expanded'}>
          <Markup content={description} />
        </div>
        <div className='button-container'>
          <button
            className='learn-more-button'
            onClick={toggleFullContent}
            onTouchEnd={toggleFullContent}
          >
            {!showFullContent ? 'Learn more' : 'Learn less'}
          </button>
          <Link to={`/course-catalog${toLink ? toLink : ''}`}>
            <Button type='button'>Enroll</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MarketingHorizontial;
