import { useEffect } from 'react';
import { auth } from '../firebase/firebase';

const useSignOutUser = () => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
      } else {
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = () => {
    auth.signOut();
  };

  return { signOut };
};

export default useSignOutUser;
