import React, { useEffect } from 'react';
import Layout from '../../components/layout/Layout';
import SignUpPage from '../sign-up-page/SignUpPage';
import Page from '../../components/page/Page';
import EnrollmentForm from '../../components/enrollment-form/EnrollmentForm';
import { useAppSelector } from '../../../store/hooks';

const EnrollmentPage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.currentUser);
  const [showSignInWidget, setShowSignInWidget] = React.useState(false);

  useEffect(() => {
    if (!user) {
      setShowSignInWidget(true);
    } else {
      setShowSignInWidget(false);
    }
  }, [user]);

  return (
    <>
      {showSignInWidget ? (
        <SignUpPage />
      ) : (
        <Layout>
          <Page title='Enrollment'>
            <EnrollmentForm />
          </Page>
        </Layout>
      )}
    </>
  );
};

export default EnrollmentPage;
