import React from 'react';
import './Overlay.scss';

type OverlayProps = {
  children?: React.ReactNode;
  isOpen: boolean;
};

const Overlay: React.FC<OverlayProps> = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return <div className='overlay'>{children}</div>;
};

export default Overlay;
