import React, { useEffect } from 'react';
import './Page.scss';

type PageProps = {
  title: string;
  children?: React.ReactNode;
};

const Page: React.FC<PageProps> = ({ title, children }) => {
  useEffect(() => {
    document.title = `Restless Outfitter - ${title}`;
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <div className='page'>
      <div className='page-content'>{children}</div>
    </div>
  );
};

export default Page;
