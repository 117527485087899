import React, { useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from '../form/Form';
import FormInput from '../form-input/FormInput';
import Button from '../button/Button';
import WidgetContainer from '../widget-container/WidgetContainer';
import { emailPattern } from '../../../utils/field-validations/fieldValidations';
import './RegisterWidget.scss';
import Message, { MessageProps } from '../message/Message';
import { RegisterAsync } from '../../../store/auth/authSlice';

export type RegistrationFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export interface RegisterProps {
  setWidget: any;
}

const RegisterWidget: React.FC<RegisterProps> = ({ setWidget }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<RegistrationFormFields>();

  const password = watch('password');

  const validatePassword = (value: string) => {
    return value === password || 'Passwords do not match.';
  };

  const [message, setMessage] = useState<MessageProps>({
    type: 'error',
    message: '',
  });

  const getErrorMessage = (error: string | undefined) => {
    switch (error) {
      case 'Firebase: Error (auth/email-already-in-use).':
        return 'Email address is already in use.';
      case 'Firebase: Error (auth/invalid-email).':
        return 'Invalid email address.';
      case 'Firebase: Error (auth/operation-not-allowed).':
        return 'Operation not allowed. Please contact support.';
      case 'Firebase: Error (auth/weak-password).':
        return 'Password is too weak.';
      case 'Firebase: Error (auth/network-request-failed).':
        return 'Network request failed. Please check your internet connection and try again.';
      default:
        return 'An unknown error occurred.';
    }
  };

  const onSubmit = handleSubmit(async () => {
    const values: RegistrationFormFields = getValues();
    dispatch(RegisterAsync(values)).then((result) => {
      if (RegisterAsync.fulfilled.match(result)) {
        navigate('/');
      } else {
        const error = getErrorMessage(result.payload);
        setMessage({
          message: error,
          type: 'error',
        });
      }
    });
  });

  return (
    <WidgetContainer>
      <h2>Create new account</h2>
      {message.message && <Message type={message.type} message={message.message} />}
      <Form onSubmit={onSubmit}>
        <FormInput<RegistrationFormFields>
          key='firstName'
          id='firstName'
          type='text'
          name='firstName'
          label='First Name'
          placeholder='First Name'
          register={register}
          rules={{ required: 'Enter your first name' }}
          errors={errors}
        />
        <FormInput<RegistrationFormFields>
          key='lastName'
          id='lastName'
          type='text'
          name='lastName'
          label='Last Name'
          placeholder='Last Name'
          register={register}
          rules={{ required: 'Enter your Last name' }}
          errors={errors}
        />
        <FormInput<RegistrationFormFields>
          key='email'
          id='email'
          type='email'
          name='email'
          label='Email Address'
          placeholder='Email Address'
          register={register}
          rules={{
            required: 'Enter your email address.',
            pattern: emailPattern,
          }}
          errors={errors}
        />
        <FormInput<RegistrationFormFields>
          key='password'
          id='password'
          type='password'
          name='password'
          label='Password'
          placeholder='Password'
          register={register}
          rules={{
            required: 'Enter your password.',
            minLength: {
              value: 6,
              message: 'Enter a password with at least 6 characters.',
            },
          }}
          errors={errors}
        />
        <FormInput<RegistrationFormFields>
          key='confirmPassword'
          id='confirmPassword'
          type='password'
          name='confirmPassword'
          label='Confirm Password'
          placeholder='Confirm Password'
          register={register}
          rules={{
            required: 'Confirm your password.',
            validate: validatePassword,
          }}
          errors={errors}
        />
        <Button type='button' className='blue-link-button' onClick={() => setWidget('login')}>
          Go back to login.
        </Button>
        <div className='button-container'>
          <Button type='submit' content='Register' />
        </div>
      </Form>
    </WidgetContainer>
  );
};

export default RegisterWidget;
