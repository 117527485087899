type contentObj = {
  [key: string]: string;
}

const content: contentObj = {
  'marketing.marksmanship': `
    <p>Marksmanship is the ability to accurately and effectively shoot a firearm. In a defensive shooting encounter, marksmanship is essential for several reasons.</p>
    <p>First and foremost, marksmanship is critical for ensuring the safety of oneself and others in the vicinity. When using a firearm in self-defense, it's crucial to aim and hit the target accurately to eliminate the threat without causing harm to innocent bystanders.</p>
    <p>Furthermore, marksmanship can also help to reduce the likelihood of a defensive shooting situation occurring in the first place. The mere presence of a firearm can sometimes be enough to deter a potential attacker, but in cases where shooting is necessary, a skilled marksman is better equipped to quickly and effectively neutralize the threat.</p>
    <p>Overall, marksmanship is an essential component of defensive shooting.</p>
    `,
  'marketing.defensive.tactics': `
    <p>Defensive tactics are the techniques and strategies used to defend oneself against an attacker. In a defensive shooting encounter, defensive tactics are essential for several reasons.</p>
    <p>Firstly, defensive tactics can help a shooter to create distance between themselves and their attacker. Using footwork and positioning, a shooter can move away from an attacker or create obstacles between themselves and the attacker. The distance can increase the shooter's chances of survival and provide more time to use a firearm if necessary.</p>
    <p>Additionally, defensive tactics can help a shooter to neutralize an attacker without using a firearm. In some situations, a firearm may not be the best option or may not be readily available. In these cases, defensive tactics can be utilized to physically subdue an attacker or immobilize them long enough to call for help.</p>
    <p>Defensive tactics can also help a shooter to assess a situation better and make more informed decisions. By remaining calm and focused, a shooter can more accurately evaluate the danger level posed by an attacker and determine the appropriate course of action.</p>
    <p>Furthermore, defensive tactics can help minimize the risk of legal consequences arising from a defensive shooting. In some cases, using excessive force or using a firearm when unnecessary may lead to legal repercussions. By possessing good defensive tactics skills, a shooter can demonstrate that they made a reasonable effort to de-escalate the situation before resorting to deadly force.</p>
    <p>Overall, defensive tactics are an essential component of defensive shooting. They provide additional tools and strategies for a shooter to defend themselves in high-pressure situations effectively and can help to ensure the safety of themselves and others.</p>
  `,
  'marketing.resilence.mindset': `
    <p>A resilient mindset is a learnable ability to remain calm, focused, and mentally strong in high-pressure situations. A resilient mindset is essential in a defensive shooting encounter for several reasons.</p>
    <p>Firstly, a resilient mindset can help a shooter to remain calm and make rational decisions under pressure. When faced with a life-threatening situation, it's natural for the body to respond with fear and panic. However, a shooter with a resilient mindset can recognize and control these emotions, allowing them to remain focused on the task at hand and make clear-headed decisions.</p>
    <p>Additionally, a resilient mindset can help a shooter to persevere in the face of adversity. Defensive shooting encounters are often fast-paced and intense, requiring high physical and mental endurance. A shooter with a resilient mindset is better equipped to push through physical and psychological fatigue and continue to perform effectively.</p>
    <p>Furthermore, a resilient mindset can help a shooter to adapt to changing circumstances. Defensive shooting encounters are unpredictable, and the situation can change rapidly. A shooter with a resilient mindset can quickly assess the situation and adjust their strategy accordingly, maximizing their chances of success.</p>
    <p>Moreover, a resilient mindset can help a shooter to recover from the emotional and psychological effects of a defensive shooting encounter. These events can be traumatic and have a lasting impact on mental health. A shooter with a resilient mindset is better equipped to cope with the aftermath of a defensive shooting encounter and seek appropriate support if necessary.</p>
    <p>Overall, a resilient mindset is an essential component of defensive shooting, and it allows a shooter to remain calm, focused, and mentally strong in high-pressure situations, increasing their chances of success and ensuring their safety and the safety of others.</p>
  `,
  'marketing.courses.handgun': `<p>We are thrilled to offer an array of exhilarating handgun courses that cater to every skill level and scenario imaginable. Whether you're just starting and need to learn the fundamentals of handgun marksmanship or are an experienced marksman looking to master advanced techniques and tactical strategies, our courses have got you covered. Our expert instructors provide personalized attention and training to ensure you gain the knowledge, skills, and resilient mindset needed to handle any situation confidently and precisely. From defensive shooting to precision marksmanship, our courses are designed to challenge and excite you while preparing you for real-world scenarios. Join us and take the first step towards becoming a confident, capable, skilled handgun enthusiast!</p>`,
  'marketing.courses.rifle': `<p>We offer an adrenaline-pumping array of courses to take your rifle skills to the next level. Our courses cover various scenarios, from mastering the fundamentals of rifle marksmanship to handling long guns in confined environments and even close-range encounters with a long gun. Our expert instructors will guide you through the ins and outs of long-range precision shooting and teach you the advanced techniques needed to excel in tactical scenarios. And that's not all – our courses emphasize the importance of a resilient mindset, helping you develop the mental fortitude required to perform at your best under any circumstance. Our approach gives you access to top-of-the-line training resources, giving you the tools to hone your skills and achieve your goals. So if you're ready to take your rifle skills to the next level, join us for an unforgettable training experience that will leave you feeling empowered and prepared for anything!</p>`,
  'marketing.courses.home.defense': `<p>We're thrilled to offer an essential home defense and security course that goes beyond the basic concept of just owning a gun for self-defense in the home. Our course is designed to equip you with the skills and knowledge to defend your home effectively against any potential threat. From developing a comprehensive security plan to identifying and mitigating potential risks, our expert instructors will guide you through every step of the process. Our course is not just about owning a gun; we cover various topics and scenarios vital to keeping you and your loved ones safe, including home invasion, burglary, and emergency response. Our instructors are highly trained professionals with years of experience in the field, so you can rest assured that you're getting the best in home defense and security education. We provide a safe and supportive learning environment, ensuring that you feel confident and prepared to handle any situation that may arise. So don't wait any longer – sign up for our home defense and security course today and take the first step towards safeguarding your home and family!</p>`,
};

export const getMessage = (key: string, ...params: string[]) => {
  return params.reduce((preValue, currValue) => {
    return `${preValue} ${currValue}`
  }, content[key])
}
