import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import useAuthStateChange from '../../../hooks/useSignOutUser';
import './Navigation.scss';
import { useAppSelector } from '../../../store/hooks';

interface NavItemProps {
  label: string;
  to: string;
}

interface NavigationProps {
  handleNavToggle: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ label, to }) => (
  <li>
    <Link to={to}>{label}</Link>
  </li>
);

const Navigation: React.FC<NavigationProps> = ({ handleNavToggle }) => {
  const userDisplayName = useAppSelector((state) => state.auth.currentUser?.displayName);
  const { signOut } = useAuthStateChange();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    handleNavToggle();
  };

  const navItems: () => NavItemProps[] = () => {
    const items = [
      { label: 'Home', to: '/' },
      { label: 'Courses', to: '/course-catalog' },
      { label: 'Contact', to: '/contact' },
      { label: 'Login', to: '/login' },
    ];
    if (!userDisplayName) {
      return items;
    } else {
      return items.reduce((acc: NavItemProps[], item) => {
        if (item.label !== 'Login') {
          acc.push(item);
        }
        return acc;
      }, []);
    }
  };

  return (
    <nav className='navigation'>
      <div className='navigation-contents'>
        <div className='hamburger-btn' onClick={handleToggle}>
          <Hamburger toggled={isOpen} toggle={setIsOpen} color='#fff' />
        </div>
        <ul className={`navigation-menu ${isOpen ? 'open' : ''}`}>
          {navItems().map((item) => (
            <NavItem key={item.to} label={item.label} to={item.to} />
          ))}
          {userDisplayName && (
            <li>
              <button className='logout-btn' onClick={signOut}>
                {`Hi, ${userDisplayName} logout`}
              </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
