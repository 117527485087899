import React from 'react';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import { useParams } from 'react-router-dom';

const EnrollmentSuccessPage: React.FC = () => {
  const { class: classid } = useParams();

  return (
    <>
      <Layout>
        <Page title='Enrollment Success'>
          <div style={{ padding: '20px' }}>
            <h2>{`You have successfully enrolled in ${classid?.toLocaleUpperCase()}!`}</h2>
            <p>
              Please watch your email for information on your upcoming course. Direct all inquiries
              to{' '}
              <a href={'mailto:therestlessoutfitters@gmail.com'}>therestlessoutfitters@gmail.com</a>
              .
            </p>
          </div>
        </Page>
      </Layout>
    </>
  );
};

export default EnrollmentSuccessPage;
