import React, { forwardRef } from 'react';
import './InputDate.scss';

export type InputDateProps = {
  id: string;
  type: string;
  name: string;
  label: string;
  placeholder: string;
};

const InputDate: React.FC<InputDateProps> = forwardRef<HTMLInputElement, InputDateProps>(
  ({ id, name, label, type = 'date', placeholder, ...props }, ref) => {
    return (
      <>
        <input
          className='input-date'
          id={name}
          ref={ref}
          name={name}
          type={type}
          aria-label={label}
          placeholder={placeholder}
          {...props}
        />
        <label htmlFor={label}>{label}</label>
      </>
    );
  }
);

export default InputDate;
