import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import './Header.scss';

interface Props {
  handleNavToggle: () => void;
}

const Header: React.FC<Props> = ({ handleNavToggle }) => {
  return (
    <header>
      <div className='header-wrapper'>
        <Link to='/'>
          <h1>Restless Outfitters</h1>
        </Link>
        <Navigation handleNavToggle={handleNavToggle} />
      </div>
    </header>
  );
};

export default Header;
