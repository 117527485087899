const emailPattern = {
  value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
  message: 'Enter a valid email address.',
};

const specialCodePattern = {
  value: new RegExp('idahoex', 'i'),
  message: 'Code is not valid.',
};

export const phoneNumberValidations = (phoneNumber: string | undefined) => {
  const cleanInput = phoneNumber?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // allow only numbers
  return (
    cleanInput?.match(/^[0-9]{0,9}$/g) ||
    cleanInput?.match(/^([01][0-9]{2}|900|999)([0-9]{7})$/g) || // invalid area code
    cleanInput?.match(
      /^([0-9]{3}5551212)|(([0-9])\3{9})|1234567890|0123456789|9876543210|0987654321$/g
    )
  ); // invalid number
};

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  const cleanInput = phoneNumber?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // allow only numbers
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; // format (xxx) xxx-xxxx
  return cleanInput?.replace(phoneRegex, '($1) $2-$3');
};

const validateTextInput = (value: string): string => {
  if (!value.trim()) {
    return 'Field is required';
  }
  if (value.trim().length < 2) {
    return 'Field must be at least 2 characters long';
  }
  if (value.trim().length > 50) {
    return 'Field must be less than 50 characters long';
  }
  if (!/^[a-zA-Z0-9]+$/.test(value)) {
    return 'Field must contain only letters';
  }
  return '';
};

// Utility function for validating email input fields
const validateEmailInput = (value: string): string => {
  if (!value.trim()) {
    return 'Field is required';
  }
  if (!/\S+@\S+\.\S+/.test(value)) {
    return 'Invalid email format';
  }
  return '';
};

// Utility function for validating password input fields
const validatePasswordInput = (value: string): string => {
  if (!value.trim()) {
    return 'Field is required';
  }
  if (value.trim().length < 6) {
    return 'Password must be at least 6 characters long';
  }
  return '';
};

// Utility function for validating number input fields
const validateNumberInput = (value: string): string => {
  if (!value.trim()) {
    return 'Field is required';
  }
  if (isNaN(Number(value))) {
    return 'Field must be a number';
  }
  return '';
};

// Utility function for validating select input fields
const validateSelectInput = (value: string): string => {
  if (value === 'default') {
    return 'Please select an option';
  }
  return '';
};

// Utility function for validating all form fields
const validateAllFields = (formState: Record<string, string>): boolean => {
  let isValid = true;
  Object.keys(formState).forEach((key) => {
    if (formState[key] !== '') {
      isValid = false;
    }
  });
  return isValid;
};

export { emailPattern, validateAllFields, validateEmailInput, validateNumberInput, validatePasswordInput, validateSelectInput, validateTextInput, specialCodePattern }
