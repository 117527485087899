import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../form-input/FormInput';
import Form from '../form/Form';
import WidgetContainer from '../widget-container/WidgetContainer';
import Button from '../button/Button';
import './PasswordResetWidget.scss';
import { resetPassword } from '../../../firebase/firebaseAuth';
import Message from '../message/Message';
import { MessageProps } from '../message/Message';
import { emailPattern } from '../../../utils/field-validations/fieldValidations';

export type PasswordResetFields = {
  email: string;
  password: string;
};

interface PasswordResetProps {
  setWidget: any;
}

const PasswordResetWidget: React.FC<PasswordResetProps> = ({ setWidget }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<PasswordResetFields>();

  const [message, setMessage] = useState<MessageProps>({
    type: 'general',
    message: '',
  });

  const getErrorMessage = (error: string | undefined) => {
    switch (error) {
      case 'auth/invalid-email':
        return 'Invalid email address.';
      case 'auth/user-disabled':
        return 'User account has been disabled.';
      case 'auth/user-not-found':
        return 'User account not found.';
      case 'auth/too-many-requests':
        return 'Too many requests, try again later.';
      case 'auth/network-request-failed':
        return 'Network request failed, try again later.';
      default:
        return 'An unknown error occurred.';
    }
  };

  const onSubmit = handleSubmit(async () => {
    const values = getValues();
    try {
      await resetPassword(values.email);
      setMessage({
        message: 'Please check your email for a password reset link.',
        type: 'general',
      });
    } catch (error) {
      const firebaseError = error as any;
      setMessage({
        message: getErrorMessage(firebaseError.code),
        type: 'error',
      });
    }
  });

  return (
    <WidgetContainer>
      <h2>Reset Password</h2>
      {message.message && <Message type={message.type} message={message.message} />}
      <Form onSubmit={onSubmit}>
        <FormInput<PasswordResetFields>
          id='email'
          type='email'
          name='email'
          label='Email Address'
          placeholder='Email Address'
          register={register}
          rules={{
            required: 'Enter your email address.',
            pattern: emailPattern,
          }}
          errors={errors}
        />
        <Button type='button' className='blue-link-button' onClick={() => setWidget('login')}>
          Go back to login.
        </Button>
        <div className='button-container'>
          <Button type='submit' content='Reset' />
        </div>
      </Form>
    </WidgetContainer>
  );
};

export default PasswordResetWidget;
