import React from 'react';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import Section from '../../components/section/Section';
import MarketingContent from '../../components/marketing-content/MarketingContent';
import MarketingHorizontial from '../../components/marketing-horizontial/marketing-horizontial';
import HorizontialRule from '../../components/horizontial-rule/HorizontialRule';
import { getMessage } from '../../../content/content';
import mainBanner from '../../../images/taking-firearms-to-the-next-level-png.png';
import marksmanImg from '../../../images/target-500-500.png';
import taticsImg from '../../../images/SighWorthy_a_couple_of_guerilla_warfare_citizens_stratigize-500-500.png';
import mindsetImg from '../../../images/fear-responses-920-519.png';
import m1911 from '../../../images/transparent-1911-1177-654.png';
import ar15 from '../../../images/transparent-ar15-1292-418.png';
import camera from '../../../images/transparent-camera-705-539.png';
import './HomePage.scss';

interface props {}

const HomePage: React.FC<props> = () => {
  return (
    <Layout>
      <Page title='Register New Account'>
        <Section>
          <img
            className='banner-img'
            src={mainBanner}
            alt='Take Your Shooting Skills to the Next Level with Advanced and Intuitive Training'
          />
          <h1 className='banner-text'>
            Take Your Shooting Skills to the Next Level with Advanced and Intuitive Training
          </h1>
        </Section>
        <Section className='marketing-section'>
          <MarketingContent
            imageSrc={marksmanImg}
            header='Marksmanship'
            content={getMessage('marketing.marksmanship')}
          />
          <MarketingContent
            imageSrc={taticsImg}
            header='Defensive Tactics'
            content={getMessage('marketing.defensive.tactics')}
          />
          <MarketingContent
            imageSrc={mindsetImg}
            header={'Resilient Mindset'}
            content={getMessage('marketing.resilence.mindset')}
          />
        </Section>
        <HorizontialRule text='Training' />
        <Section className='courses-section'>
          <MarketingHorizontial
            imageSrc={m1911}
            title={'Handgun Courses'}
            description={getMessage('marketing.courses.handgun')}
            toLink='/handgun'
          />
          <MarketingHorizontial
            imageSrc={ar15}
            title={'Rifle Courses'}
            description={getMessage('marketing.courses.rifle')}
            toLink='/rifle'
          />
          <MarketingHorizontial
            imageSrc={camera}
            title={'Home Defense Courses'}
            description={getMessage('marketing.courses.home.defense')}
            toLink='/non-shooting'
          />
        </Section>
      </Page>
    </Layout>
  );
};

export default HomePage;
