import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Overlay from '../overlay/Overlay';
import { UserComponent } from '../user/User';

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <>
      <Header handleNavToggle={handleNavToggle} />
      <Overlay isOpen={isNavOpen}></Overlay>
      <UserComponent />
      {children}
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
