import React, { forwardRef } from 'react';
import './Input.scss';

export type InputProps = {
  id: string;
  type: string;
  name: string;
  label: string;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ id, name, label, type = 'text', placeholder, onChange, ...props }, ref) => {
    return (
      <>
        <input
          id={name}
          ref={ref}
          name={name}
          type={type}
          aria-label={label}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        />
        <label htmlFor={label}>{label}</label>
      </>
    );
  }
);

export default Input;
