import React, { ReactNode } from 'react';
import './FormErrorMessage.scss';

export type FormErrorMessageProps = {
  children: ReactNode;
};

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ children }) => (
  <p className='form-error-message'>{children}</p>
);
