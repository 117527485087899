import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

interface MarkupProps {
  content: string;
}

const Markup: React.FC<MarkupProps> = ({ content }) => {
  const [markupContent, setMarkupContent] = useState<string | null>(null);

  useEffect(() => {
    if (content) setMarkupContent(content);
  }, [content]);

  if (markupContent) {
    const sanitizedHtml = DOMPurify.sanitize(markupContent);
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }
  return null;
};

export default Markup;
