import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [currentYear, setCurrentYear] = useState<string>('');

  useEffect(() => {
    const date: Date = new Date();
    setCurrentYear(date.getFullYear().toString());
  }, []);

  return (
    <footer className='footer'>
      <div className='width-restriction'>
        <div className='footer-content-container'>
          {/* <div className='footer-content-group'>
            <h3 className='footer-content-title'>Disclaimer</h3>
            <p className='footer-content'>Some legal text goes here...</p>
          </div>
          <div className='footer-content-group'>
            <h3 className='footer-content-title'>Privacy</h3>
            <p className='footer-content'>Some legal text goes here...</p>
          </div>
          <div className='footer-content-group'>
            <h3 className='footer-content-title'>Legal Notice</h3>
            <p className='footer-content'>Some legal text goes here...</p>
          </div> */}
        </div>
        <div className='footer-link-container'>
          <ul>
            <li className='footer-link'>
              <Link to='/'>Home</Link>
            </li>
            <li className='footer-link'>
              <Link to='/course-catalog'>Courses</Link>
            </li>
            <li className='footer-link'>
              <Link to='/contact'>Contact</Link>
            </li>
            <li className='footer-link'>
              <Link to='/login'>Sign up</Link>
            </li>
            {/* Add more links as needed */}
          </ul>
        </div>
        <div className='footer-copyright'>
          <p>© {currentYear} Restless Outfitters. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
