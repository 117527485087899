import { auth, firestore } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, User,
  sendPasswordResetEmail, confirmPasswordReset, updateProfile } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { RegistrationFormFields } from '../view/components/register-widget/RegisterWidget';
import { FirebaseError } from 'firebase/app';
import { LoginFormFields } from '../view/components/login/LoginWidget';
import { Dispatch } from '@reduxjs/toolkit';
import { authSlice } from '../store/auth/authSlice';

export const login = async (loginData: LoginFormFields, dispatch: Dispatch) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, loginData.email, loginData.password);
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userDoc = userSnap.data() as User;
        return userDoc;
      }
      dispatch(authSlice.actions.setCurrentUser(user))
    }
  } catch (error: any) {
    throw error
  }
};

export const registerUser = async (formData: RegistrationFormFields) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, formData.email, formData.password);

    await updateProfile(user, {
      displayName: `${formData.firstName} ${formData.lastName}`,
    });
    return user
  } catch (error: any) {
    throw error
  }
};

export const logout = () => {
  auth.signOut();
}

export const resetPassword = async (email: string) => {
  try {
    return await sendPasswordResetEmail(auth, email)
  } catch (error: any) {
    throw error
  }
}

export const confirmPassword = async (code: string, password: string) => {
  try {
    return await confirmPasswordReset(auth, code, password);
  } catch (error: any) {
    const firebaseError = error as FirebaseError;
    throw firebaseError
  }
}
