import React from 'react';
import {
  FaInfoCircle,
  FaExclamationCircle,
  FaCheckCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';
import './Message.scss';

export interface MessageProps {
  type: 'general' | 'error' | 'info' | 'warning';
  message: string;
}

const Message: React.FC<MessageProps> = ({ type, message }) => {
  let icon;
  switch (type) {
    case 'general':
      icon = <FaInfoCircle className='icon' />;
      break;
    case 'error':
      icon = <FaExclamationCircle className='icon' />;
      break;
    case 'info':
      icon = <FaCheckCircle className='icon' />;
      break;
    case 'warning':
      icon = <FaExclamationTriangle className='icon' />;
      break;
    default:
      icon = <FaInfoCircle className='icon' />;
  }

  return (
    <div className={`message ${type}`}>
      {icon}
      <p>{message}</p>
    </div>
  );
};

export default Message;
