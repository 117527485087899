import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../view/pages/home-page/HomePage';
import SignUpPage from '../view/pages/sign-up-page/SignUpPage';
import CoursesPage from '../view/pages/courses-page/CoursesPage';
import ContactPage from '../view/pages/contact-page/ContactPage';
import EnrollmentPage from '../view/pages/enrollment-page/EnrollmentPage';
import CheckoutPage from '../view/pages/checkout-page/CheckoutPage';
import EnrollmentSuccessPage from '../view/pages/enrollment-success-page/EnrollmentSuccessPage';

export const routeConfig = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/course-catalog',
    element: <CoursesPage />,
  },
  {
    path: '/course-catalog/:course',
    element: <CoursesPage />,
  },
  {
    path: '/course-catalog/:course/:class',
    element: <EnrollmentPage />,
  },
  {
    path: '/course-catalog/:course/:class/checkout',
    element: <CheckoutPage />,
  },
  {
    path: '/course-catalog/:course/:class/enrollment-success',
    element: <EnrollmentSuccessPage />,
  },
  {
    path: '/contact',
    element: <ContactPage />,
  },
  {
    path: '/register',
    element: <SignUpPage />,
  },
  {
    path: '/login',
    element: <SignUpPage />,
  },
  {
    path: '/forgot-password',
    element: <SignUpPage />,
  },
]);
