import React from 'react';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import RegisterWidget from '../../components/register-widget/RegisterWidget';
import Login from '../../components/login/LoginWidget';
import PasswordResetWidget from '../../components/password-reset/PasswordResetWidget';
import './SignUpPage.scss';

interface props {}

export type WidgetType = 'login' | 'register' | 'password-reset';

const SignUpPage: React.FC<props> = () => {
  const [widget, setWidget] = React.useState<WidgetType>('login');

  const handleWidgetChange = (widget: WidgetType) => {
    switch (widget) {
      case 'login':
        return <Login setWidget={setWidget} />;
      case 'register':
        return <RegisterWidget setWidget={setWidget} />;
      case 'password-reset':
        return <PasswordResetWidget setWidget={setWidget} />;
      default:
        return <Login setWidget={setWidget} />;
    }
  };

  return (
    <Layout>
      <Page title='Register New Account'>{handleWidgetChange(widget)}</Page>
    </Layout>
  );
};

export default SignUpPage;
