import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from '../form/Form';
import FormInput from '../form-input/FormInput';
import Button from '../button/Button';
import WidgetContainer from '../widget-container/WidgetContainer';
import { specialCodePattern } from '../../../utils/field-validations/fieldValidations';
import './SpecialtyCourseWidget.scss';
import Message, { MessageProps } from '../message/Message';

export type SpecialtyCourseCodeFormFields = {
  specialtyCourseCode: string;
};

export interface SpecialtyCourseCodeProps {
  courseCodeModelOpen: boolean;
  setCourseCodeModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpecialtyCourseWidget: React.FC<SpecialtyCourseCodeProps> = ({
  courseCodeModelOpen = false,
  setCourseCodeModelOpen,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SpecialtyCourseCodeFormFields>();

  const [message, setMessage] = useState<MessageProps>({
    type: 'error',
    message: '',
  });

  const onSubmit = handleSubmit(async () => {
    const values: SpecialtyCourseCodeFormFields = getValues();
    const { specialtyCourseCode } = values;
    if (!specialtyCourseCode) {
      setMessage({
        message: 'Please enter a course code.',
        type: 'error',
      });
    } else {
      navigate(`/course-catalog/custom-course/${specialtyCourseCode}`);
    }
  });

  return (
    <WidgetContainer isModel={true} isModelOpen={courseCodeModelOpen}>
      <Button
        className='model-close'
        type='button'
        content='X'
        onClick={() => setCourseCodeModelOpen(false)}
      />
      <h2>Enter code for your course</h2>
      {message.message && <Message type={message.type} message={message.message} />}
      <Form onSubmit={onSubmit}>
        <FormInput<SpecialtyCourseCodeFormFields>
          id='specialtyCourseCode'
          type='text'
          name='specialtyCourseCode'
          label='Course Code'
          placeholder='Course Code'
          register={register}
          rules={{
            required: 'Enter course code',
            pattern: specialCodePattern,
          }}
          errors={errors}
        />
        <div className='button-container'>
          <Button type='submit' content='Submit' />
        </div>
      </Form>
    </WidgetContainer>
  );
};

export default SpecialtyCourseWidget;
