import React, { useState } from 'react';
import Markup from '../markup/Markup';
import './MarketingContent.scss';

interface MarketingContentProps {
  imageSrc?: string;
  header?: string;
  content: string;
}

const MarketingContent: React.FC<MarketingContentProps> = ({ imageSrc, header, content }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className={`marketing-content-container ${showFullContent ? 'expanded' : ''}`}>
      {imageSrc && (
        <div className='marketing-image-container'>
          <img className='marketing-content-image' src={imageSrc} alt={header} />
        </div>
      )}
      {header && <h4 className='marketing-content-header'>{header}</h4>}
      <div
        className={!showFullContent ? 'marketing-content-text' : 'marketing-content-text-expanded'}
      >
        {<Markup content={content} />}
      </div>
      <button
        className='read-more-button'
        onClick={toggleFullContent}
        onTouchEnd={toggleFullContent}
      >
        {!showFullContent ? 'Read more' : 'Read less'}
      </button>
    </div>
  );
};

export default MarketingContent;
