import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { login, registerUser } from '../../firebase/firebaseAuth';
import { RootState } from '../store';
import { User, sendEmailVerification } from 'firebase/auth';
import { AppDispatch } from '../store';
import { RegistrationFormFields } from '../../view/components/register-widget/RegisterWidget';
import { LoginFormFields } from '../../view/components/login/LoginWidget';
import { auth, firestore } from '../../firebase/firebase';
import { EnrollmentFields } from '../../view/components/enrollment-form/EnrollmentForm';
import { updateDoc, doc, setDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';

interface AuthState {
  currentUser: User | null;
}

const initialState: AuthState = {
  currentUser: null,
}

const createLoginAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  email: string 
  password: string 
}>()

const createRegisterAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  firstName: string
  lastName: string
  email: string 
  password: string 
}>()

const createUpdateUserEnrollmentAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  firstName: string;
  middleName?: string;
  lastName: string;
  birthdate: string;
  streetAddress: string;
  city: string;
  residentState: string;
  zipCode: string;
  phoneNumber: string;
  email: string;
  emergencyContact: string;
  emergencyContactNumber: string;
  backgroudExperiance?: string;
  backgroundImpairments?: string;
  courseId: boolean;
}>()

export const loginAsync = createLoginAsyncThunk(
  'auth/login',
  async (loginData: LoginFormFields, thunkAPI) => {
    try {
      const user = await login(loginData, thunkAPI.dispatch);
      return user as User;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message as string);
    }
  }
);

export const RegisterAsync = createRegisterAsyncThunk(
  'auth/register',
  async (registerData: RegistrationFormFields, thunkAPI) => {
    try {
      const user: User = await registerUser(registerData);
      await sendEmailVerification(user);
      const { email, firstName, lastName } = registerData; 
      const formatedUserData = {
        uid: user.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        firstName,
        lastName,
        createdAt: serverTimestamp(),
        courseHistory: {}
      }
      await setDoc(doc(firestore, 'users', user.uid), formatedUserData)
      thunkAPI.dispatch(authSlice.actions.setCurrentUser(user))
      return user as User;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message as string);
    }
  }
);

export const updateUserEnrollmentAsync = createUpdateUserEnrollmentAsyncThunk(
  'auth/register',
  async (data: EnrollmentFields , thunkAPI) => {
    try {
      const docRef = doc(firestore, 'users', auth.currentUser?.uid as string)
        await updateDoc(docRef, {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          birthdate: data.birthdate,
          streetAddress: data.streetAddress,
          city: data.city,
          residentState: data.residentState,
          zipCode: data.zipCode,
          phoneNumber: data.phoneNumber,
          email: data.email,
          emergencyContact: data.emergencyContact,
          emergencyContactNumber: data.emergencyContactNumber,
          backgroundExperiance: data.backgroundExperiance,
          backgroundImpairments: data.backgroundImpairments,
          updatedAt: serverTimestamp(),
          courseHistory: arrayUnion(data.courseId)
        });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message as string);
    }
  }
);


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state: AuthState, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.currentUser = null;
    },
  }
});

export const { logout } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
