import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../button/Button';
import './PaymentForm.scss';
import { useAppSelector } from '../../../store/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

type PaymentFormProps = {
  price: number;
};

const PaymentForm: React.FC<PaymentFormProps> = ({ price }) => {
  const user = useAppSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = React.useState(false);

  const paymentHandler = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsProcessing(true);
      const response = await fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: price * 100 }),
      }).then((res) => {
        return res.json();
      });

      const {
        paymentIntent: { client_secret },
      } = response;

      const paymentResult = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement) as any,
          billing_details: {
            name: user?.displayName || 'guest',
          },
        },
      });

      setIsProcessing(false);
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === 'succeeded') {
          navigate(pathname.replace('checkout', 'enrollment-success'));
        }
      }
    } catch (error: any) {
      setIsProcessing(false);
      alert(error.message);
    }
  };

  return (
    <div className='payment-form-container'>
      <form className='checkout-form-container' onSubmit={paymentHandler}>
        <h2>Credit Card Payment: </h2>
        <div className='payment-form-inputs'>
          <CardElement />
        </div>
        <Button isLoading={isProcessing} type='submit' content='Pay now' />
      </form>
    </div>
  );
};

export default PaymentForm;
