import React from 'react';
import Layout from '../../components/layout/Layout';
import Page from '../../components/page/Page';
import './ContactPage.scss';

interface props {}

const ContactPage: React.FC<props> = () => {
  return (
    <Layout>
      <Page title='Contact'>
        <div style={{ padding: '20px' }}>
          <h2>Contact us</h2>
          <p>
            Direct all inquiries to{' '}
            <a href={'mailto:therestlessoutfitters@gmail.com'}>therestlessoutfitters@gmail.com</a>.
          </p>
        </div>
      </Page>
    </Layout>
  );
};

export default ContactPage;
