import React from 'react';
import './Section.scss';

type props = {
  children?: any;
  className?: string;
};

const Section: React.FC<props> = ({ children, className }) => {
  return <section className={className ? className : 'section-content'}>{children}</section>;
};

export default Section;
