import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Catalog.scss';

interface CatalogProps {
  courses: CourseData[];
  setCourseCodeModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CourseData {
  title: string;
  description: string;
  classes: ClassData[];
}

export interface ClassData {
  title: string;
  days: number;
  durationHours?: number;
  roundCount?: number;
  difficulty?: string;
  classSize?: {
    min: number;
    max: number;
  };
  price?: number | string;
}

const Catalog: React.FC<CatalogProps> = ({ courses, setCourseCodeModelOpen }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const activeCourse = courses[activeTab];

  let { course } = useParams();

  useEffect(() => {
    if (course) {
      const courseDirectory = () => {
        switch (course) {
          case 'handgun':
            return 0;
          case 'rifle':
            return 1;
          case 'non-shooting':
            return 2;
          case 'advanced':
            return 3;
          default:
            return 0;
        }
      };
      setActiveTab(courseDirectory);
    }
  }, [course]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const handleRequestClass = () => {
    // TODO: Implement request class functionality
  };

  return (
    <div className='catalog'>
      <div className='catalog__tabs'>
        {courses.map((course, index) => (
          <button
            key={index}
            className={`catalog__tabs__tab ${
              index === activeTab ? 'catalog__tabs__tab--active' : ''
            }`}
            onClick={() => handleTabClick(index)}
          >
            {course.title}
          </button>
        ))}
      </div>
      <div className='catalog__course'>
        <h2 className='catalog__course__title'>{activeCourse.title}</h2>
        <p className='catalog__course__description'>{activeCourse.description}</p>
        <div className='catalog__course__classes'>
          {activeCourse.classes.map((c, index) => (
            <div key={index} className='catalog__course__classes__class'>
              <h3 className='catalog__course__classes__class-title'>{c.title}</h3>
              <p className='catalog__course__classes__class-item'>Days: {c.days}</p>
              {c.durationHours && (
                <p className='catalog__course__classes__class-item'>
                  Time: {c.durationHours} hours
                </p>
              )}
              {c.roundCount && (
                <p className='catalog__course__classes__class-item'>
                  Approximate round count: {c.roundCount}
                </p>
              )}
              {c.difficulty && (
                <p className='catalog__course__classes__class-item'>Difficulty: {c.difficulty}</p>
              )}
              {c.classSize && (
                <p className='catalog__course__classes__class-item'>
                  Student limit: {`${c.classSize.min} - ${c.classSize.max}`}
                </p>
              )}
              {c.price && <p className='catalog__course__classes__class-item'>Price: {c.price}</p>}
              <button
                className='catalog__course__classes__class-enroll'
                onClick={() => navigate(`/course-catalog/${c.title.replaceAll(' ', '-')}`)}
                disabled={c.title.includes('coming soon')}
              >
                Enroll
              </button>
            </div>
          ))}
        </div>
        <button
          className='catalog__course__request-class'
          onClick={handleRequestClass}
          disabled={true}
        >
          Request Private Class
        </button>
        <button
          className='catalog__course__enter-code'
          onClick={() => setCourseCodeModelOpen(true)}
        >
          Enter course code
        </button>
      </div>
    </div>
  );
};

export default Catalog;
