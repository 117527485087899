import React, { ReactNode } from 'react';
import './Form.scss';

type FormProps = {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  children: ReactNode;
};

const Form = ({ children, onSubmit }: React.PropsWithChildren<FormProps>) => {
  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleFormSubmit} className={'form-container'} noValidate>
      {children}
    </form>
  );
};

export default Form;
